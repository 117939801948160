import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="従業員向けコンテンツ" mdxType="SEO" />
    <PageTitle title="従業員向け" enTitle="For Employees" mdxType="PageTitle" />
    <h2 {...{
      "id": "概要",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%A6%82%E8%A6%81",
        "aria-label": "概要 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`概要`}</h2>
    <p>{`従業員向けのコンテンツや、Spindleを運用するためのガイドラインなどを公開しています。`}</p>
    <h3 {...{
      "id": "社員スタッフの皆さんへ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%A4%BE%E5%93%A1%E3%82%B9%E3%82%BF%E3%83%83%E3%83%95%E3%81%AE%E7%9A%86%E3%81%95%E3%82%93%E3%81%B8",
        "aria-label": "社員スタッフの皆さんへ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`社員・スタッフの皆さんへ`}</h3>
    <p>{`現在、従業員向けのコンテンツはこのサイト上からはアクセスができません。そのため必要な情報やファイルがある場合は、Slackの#ameba-designsystem-spindleまでお問い合わせください。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      